jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchstart", handle, { passive: false });
        } else {
            this.addEventListener("touchstart", handle, { passive: true });
        }
    }
};

jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchmove", handle, { passive: false });
        } else {
            this.addEventListener("touchmove", handle, { passive: true });
        }
    }
};


/**** USER LOGIN ****/

getStatus();

function getStatus() {
    $.get('/cart/status', function (response) {
        var data = JSON.parse(response);

        if (data.user != null) {
            $(".user-login").addClass("active");
            $(".user-login").attr("title","Moj profil");
        } else {
            $(".user-login").removeClass("active");
            $(".user-login").attr("title","Prijavi se");
        }
    });
}

/*** Fancybox ***/
Fancybox.bind("[data-fancybox]", {
    clickOutside: "close"
});

/**** END USER LOGIN ****/

// The function actually applying the offset
function offsetAnchor() {
    if (location.hash.length !== 0) {
      window.scrollTo(window.scrollX, window.scrollY - 180);
    }
}
  
// Captures click events of all <a> elements with href starting with #
$(document).on('click', 'a[href^="#"]', function(event) {
// Click events are captured before hashchanges. Timeout
// causes offsetAnchor to be called after the page jump.
window.setTimeout(function() {
    offsetAnchor();
}, 0);
});
  
  // Set the offset when entering page with hash present in the url
window.setTimeout(offsetAnchor, 0);


/**** SLIDERS ****/

if ($('.follow-list-carousell').length > 0) {
    var splidea = new Splide('.follow-list-carousell', {
        type   : 'loop',
        drag   : 'free',
        pagination: false,
        arrows: false,
        autoWidth: true,
        focus  : 'center',
        autoStart: true,
        autoScroll: {
          speed: 1,
        }
    }).mount(window.splide.Extensions);
};

if($('.exposed-products').length > 0) {
    document.addEventListener( 'DOMContentLoaded', function() {
        var splideOf = new Splide( '.exposed-products', {
        pagination: true,
        arrows: true,
        drag: true,
        autoplay: false,
        speed: 3000,
        rewind: true,
        perPage: 4,
        type: 'loop',
        padding: 20,
        breakpoints: {
            1400: {
                arrows: false,
            },
            1024: {
                perPage: 3,
            },
            720: {
                perPage: 2,
                padding: 10,
            },
            350: {
                perPage: 1,
            }
          }
        }).mount();
    });
};

/**** END SLIDERS ****/

/**** COOKIES START ****/

$(".close-promo-window").on('click', function(){
    Cookies.set('promo-modal', 'true', { expires: 7, path: '' });
});

/**** COOKIES END ****/

/**** PROMO MODAL ****/

if(Cookies.get('promo-modal') == 'true' ){
    $("#bannerPromoModal").modal('hide');
} else {
    $("#bannerPromoModal").modal();
}

/**** END PROMO MODAL ****/

/**** JUICER START ****/

var juicerFeed = $('.juicer-feed');
if (juicerFeed.length > 0) {
    $(window).on('scroll', (lazyLoadJuicer));

    var juicerFeedScrollDistance = juicerFeed.offset().top;
    var juicerLoaded = false;

    function lazyLoadJuicer() {
        var scrollDistance = $(window).scrollTop();
        var windowHeight = $(window).height();

        if ((scrollDistance >= (juicerFeedScrollDistance - windowHeight)) && !juicerLoaded) {
            //$.getScript('//assets.juicer.io/embed.js');
            $.getScript('//assets.juicer.io/embed-no-jquery.js');
            $('head').append('<link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css" />');
            juicerLoaded = true;
        }
    };

    lazyLoadJuicer();
}

/**** JUICER STOP ****/

/**** MENU ****/

$("#hamburger").on('click', function(){
    $('body').toggleClass("overflow");
    $('.bot-header').toggleClass("open");
});

$(".mobile-hamburger").on('click', function() {
    $('body').removeClass("overflow");
    $('.bot-header').removeClass("open");
});

$(".show-more").on('click', function(){
    $(this).parent("li.level1").children(".level-two").toggle();
});

$(window).on('scroll', function () {
    if ($(this).scrollTop() >= 40) {
        $('.header-container').addClass("scroll");
        $('.main-navi-wrapper').addClass("scroll");
        $('.side-menu-wrapper').addClass("scroll");
    } else {
        $('.header-container').removeClass("scroll");
        $('.main-navi-wrapper').removeClass("scroll");
        $('.side-menu-wrapper').removeClass("scroll");
    }
});

/**** END MENU ****/

/**** NEWSLETTER SUBSCRIBE ****/

$("#newsletter-subscribe").on("submit", function (e) {
    e.preventDefault();
    var form = $(this)[0];
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
    $.ajax({
        type: 'post',
        url: $(this).attr("action"),
        data: $(this).serialize(),
        success: function (data) {
            $("#submitedEmail").html($("#subscribeEmail").val());
            $("#newsletterModal").modal()
        }
    });
});

/**** END NEWSLETTER SUBSCRIBE ****/

/**** Find bike start ****/

$(".close-perfect-bike").on('click', function() {
    $("#perfect-bike").hide();
});

/**** Find bike end ****/

function formValidation() {
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener("keydown", function () {
            if (document.querySelector(".invalid-vat")) {
                document.querySelector(".invalid-vat").style.display = "none";
            }
            if (document.querySelector(".invalid-long")) {
                document.querySelector(".invalid-long").style.display = "none";
            }
            if (document.querySelector(".invalid-tooltip")) {
                document.querySelector(".invalid-tooltip").style.display = "none";
            }
        });
        
        form.addEventListener("submit", function (event) {
            if (document.querySelector(".invalid-vat")) {
                document.querySelector(".invalid-vat").style.display = "none";
            }
            if (document.querySelector(".invalid-long")) {
                document.querySelector(".invalid-long").style.display = "none";
            }
            
            if (typeof document.getElementById("password").value === undefined) {
                return false;
            }
            
            if (typeof document.getElementById("password").value !== undefined) {
                var password = document.getElementById("password").value;
            }
            
            if (document.getElementById("vat") !== null) {
                var vat = document.getElementById("vat").value;
                if (vat.length > 8) {
                    document.querySelector(".invalid-long").style.display = "block";
                }
                
                var regex = /^[0-9]{8}$/gm;
                if (!vat.match(regex)) {
                    document.querySelector(".invalid-vat").style.display = "block";
                }
            }
            
            if (document.getElementById("billing_vat_number") !== null) {
                var vat = document.getElementById("billing_vat_number").value;
                if (vat.length > 8) {
                    document.querySelector(".invalid-long").style.display = "block";
                }
                
                var regex = /^[0-9]{8}$/gm;
                if (!vat.match(regex)) {
                    document.querySelector(".invalid-vat").style.display = "block";
                }
            }
            
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                $(form).addClass('has-errors');
            } else {
                $(form).removeClass('has-errors');
                $(form).addClass('submitting');
            }
            form.classList.add('was-validated');
        }, false);
    });
}

if ($("#checkout").length > 0) {
    window.addEventListener('load', formValidation(), false);
}

// On dropdown open
$(document).on('shown.bs.dropdown', function (event) {
    var dropdown = $(event.target);

    // Set focus on the first link in the dropdown
    setTimeout(function () {
        dropdown.find('.dropdown-menu a:first-child').focus();
    }, 10);
});

// On dropdown close
$(document).on('hidden.bs.dropdown', function (event) {
    var dropdown = $(event.target);

    // Set focus back to dropdown toggle
    dropdown.find('.dropdown-toggle').focus();
});

$(".newsletter-subscribe").on("submit", function (e) {
    e.preventDefault();

    var form = $(this)[0];
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    var fbqData = {
        "content_category": "Newsletter",
        "content_name": $(this).find(".emailInput").val(),
    };
    fbq('track', 'Lead', fbqData);

    $.ajax({
        type: 'post',
        url: $(this).attr("action"),
        data: $(this).serialize(),
        success: function (data) {
            $("#submitedEmail").html($(".subscribeEmail").val());
            $("#newsletterModal").modal()
        }
    });
});
